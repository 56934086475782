
































































































































































































import axios from 'axios';
import _ from 'lodash';
import * as math from 'mathjs';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type {
  Category,
  Connection,
  Contact,
  Invoice,
  InvoicePaymentLine,
  InvoicePaymentLineInput,
  Transaction,
  Wallet,
} from '@/api-svc-types';
import { InvoiceInputType, InvoiceType, Providers, ReconciliationStatus } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiLoading from '@/components/ui/UiLoading.vue';
import { getSymbolForCoin, getSymbolForCurrency } from '@/utils/coinUtils';

import { InvoicesQuery } from '../../../queries/transactionsPageQuery';
import CostBasis from './CostBasis.vue';
import { calculateForex } from './invoiceCategorizationUtilities';
import { CostBasisDTO, TransactionDataDTO } from './types';

@Component({
  components: {
    CostBasis,
    UiLoading,
  },
})
export default class InvoiceCategorization extends BaseVue {
  @Prop({ required: true })
  readonly txn!: Transaction;

  @Prop({ required: true })
  readonly categories!: Category[];

  @Prop({ required: true })
  readonly contacts!: Contact[];

  @Prop({ required: true })
  readonly txnType!: 'receive' | 'spend';

  @Prop({ required: true })
  readonly readonly!: boolean;

  @Prop({ required: true })
  readonly connection!: Connection;

  @Prop({ required: true })
  readonly wallets!: Wallet[];

  costBasis: CostBasisDTO | null = null;
  paymentLineInputs: InvoicePaymentLineInput[] = [];
  forexCategoryId: null | string = null;
  feeContactId: null | string = null;
  feeWalletId: null | string = null;
  errors: string[] = [];
  invoices: Invoice[] = [];
  isLoading = 0;
  apiBaseUrl = process.env.VUE_APP_API_URL;

  rules = {
    number: (value: unknown) => {
      if (isNaN(Number(value))) {
        return 'Invalid Number';
      }
      return true;
    },
  };

  async getInvoices(contactId: string, pageToken?: string | undefined) {
    this.isLoading++;
    try {
      let endpointUrl = `${this.apiBaseUrl}invoices/${this.$store.state.currentOrg.id}?contactId=${contactId}`;
      if (pageToken) {
        endpointUrl += `&lastRef=${pageToken}`;
      }
      const originalPromise = axios.get(endpointUrl, {
        withCredentials: true,
      });
      const resp = await originalPromise;
      if (resp.status === 200) {
        this.invoices.push(...resp.data.records);
        if (resp.data.nextPageToken) await this.getInvoices(contactId, resp.data.nextPageToken);
      }
    } catch (e: any) {
      console.error('Error getting invoices', e);
    } finally {
      this.isLoading--;
    }
  }

  async onContactChanged(contactId: string) {
    const currentContactIds = this.paymentLineInputs.map((x) => x.contactId);
    this.invoices = this.invoices.filter((x) => currentContactIds.includes(x.contactId || ''));
    await this.getInvoices(contactId);
    this.updateTransactionData();
  }

  async mounted() {
    const savedInvoices = this.txn.accountingDetails?.[0]?.invoice?.invoices;

    // if the transaction is reconciled do not filter invoices by contact id,
    // this is a workaround for some reconciled transactions that have mismatching contact/invoice
    if (this.isReconciled) {
      this.getInvoices('');
    } else if (savedInvoices?.length) {
      for (let i = 0; i < savedInvoices.length; i++) {
        if (savedInvoices[i]?.contactId) await this.getInvoices(savedInvoices[i]?.contactId || '');
      }
    }

    this.populateForm();

    if (!this.paymentLineInputs.length) {
      if (this.txn?.txnLines?.length) {
        const amounts = this.txn?.txnLines.filter((l) => l?.operation !== 'FEE') ?? [];

        amounts.forEach((amount, i) => {
          if (!amount) {
            return;
          }
          this.addLine();
          this.paymentLineInputs[i].sourceTicker = amount.asset ?? '';
          this.paymentLineInputs[i].sourceAmount = amount.amount ? math.abs(Number(amount.amount)).toString() : '';
          this.paymentLineInputs[i].walletId = amount.walletId ?? '';
          this.paymentLineInputs[i].walletDisabled = true;
        });
      } else {
        const amounts = (this.txn?.fullAmountSetWithoutFees || this.txn?.fullAmountSet) ?? [];

        amounts.forEach((amount, i) => {
          if (!amount) {
            return;
          }
          this.addLine();
          this.paymentLineInputs[i].sourceTicker = amount.coin ?? '';
          this.paymentLineInputs[i].sourceAmount = amount.value ? math.abs(Number(amount.value)).toString() : '';
        });
      }
      this.feeWalletId = this.paymentLineInputs?.[0]?.walletId ?? null;
    }
    this.updateTransactionData();
  }

  get filteredContacts(): Contact[] {
    if (this.isReconciled) {
      return this.contacts;
    }

    let filtered = [];
    if (this.txnType === 'receive') {
      filtered = _.filter(this.contacts, (m) => !m.type || m.type === 'None' || m.type === 'Customer');
    } else {
      filtered = _.filter(this.contacts, (m) => !m.type || m.type === 'None' || m.type === 'Vendor');
    }
    // filtered = _.filter(
    //   filtered,
    //   (m) => !!this.invoices.filter((inv) => inv.contactId === m.id && inv.dueAmount).length
    // );
    const sortedByName = _.sortBy(filtered, (m) => m.name);
    return sortedByName;
  }

  filteredInvoices(contactId: string) {
    if (this.isReconciled) {
      return this.invoices;
    }

    const syncCheck = (invoice?: Invoice, connection?: Connection) => {
      if (!connection) {
        return true;
      }

      if (connection.provider === Providers.NetSuite) {
        if (!invoice?.lastUpdatedSEC || !connection?.lastSyncSEC) return true;
        return Math.abs(invoice.lastUpdatedSEC - connection.lastSyncSEC) / (1000 * 60) <= 20; // 20 minutes
      }

      return true;
    };

    let filteredInv: Invoice[] = [];
    if (this.connection) {
      filteredInv = this.invoices.filter(
        (c) =>
          this.contacts.find((x) => c.contactId === x.id)?.accountingConnectionId === this.connection.id &&
          syncCheck(c, this.connection)
      );
    }

    const staticChoices = [];

    if (this.checkFeatureFlag('unapplied-payment', this.features)) {
      const unapplied: Invoice = {
        id: 'Unapplied',
        type: InvoiceType.Receiving,
        title: 'Unapply Amount',
      };
      staticChoices.push(unapplied);
    }

    const inv = filteredInv
      .filter((inv) => {
        // is an unpaid invoice
        const isUnpaidInvoice = inv.dueAmount && inv.status === 'AwaitingPayment';
        // has already been included in the payment lines
        // if it is paid and is in payment lines then it must have come from the transaction's accounting details
        const isInPaymentLines = this.paymentLineInputs.some((line) => line.invoiceId === inv.id);
        return inv.contactId === contactId && (isUnpaidInvoice || isInPaymentLines);
      })
      .map((inv) => {
        return {
          ...inv,
          title: `${inv.title} - ${inv.totalAmount}`,
        };
      });

    // sort inv by lastSyncSEC desc, null last
    inv.sort((a, b) => {
      if (!a.lastUpdatedSEC) {
        return 1;
      }
      if (!b.lastUpdatedSEC) {
        return -1;
      }
      return b.lastUpdatedSEC - a.lastUpdatedSEC;
    });
    return staticChoices.concat(inv);
  }

  addLine() {
    this.paymentLineInputs.push({
      invoiceId: '',
      amountPaid: '',
      ticker: this.$store.state.currentOrg.baseCurrency,
      contactId: '',
      forex: {
        categoryId: '',
        fiat: this.$store.state.currentOrg.baseCurrency,
        amount: '',
      },
      sourceTicker: '',
      sourceAmount: '',
    });
  }

  get coins() {
    const amounts = this.txn?.amounts ?? [];
    return amounts.map((el) => el?.coin);
  }

  getDueDate(invoiceId: string) {
    return this.invoices.find((inv) => inv.id === invoiceId)?.dueDate ?? '';
  }

  getDueAmount(invoiceId: string) {
    return this.invoices.find((inv) => inv.id === invoiceId)?.dueAmount ?? '';
  }

  getTotalAmount(invoiceId: string) {
    return this.invoices.find((inv) => inv.id === invoiceId)?.totalAmount ?? '';
  }

  getInvoiceCurrency(invoiceId: string) {
    const currency = this.invoices.find((inv) => inv.id === invoiceId)?.currency ?? '';
    const symbol = getSymbolForCurrency(currency);
    if (symbol === '??') {
      return getSymbolForCoin(currency);
    }

    return symbol;
  }

  populateForm() {
    if (!this.txn.accountingDetails) {
      return;
    }

    if (this.txn.accountingDetails.length !== 1) {
      return;
    }

    const [ad] = this.txn.accountingDetails;

    if (!ad || !ad.invoice) {
      return;
    }

    const invoices: InvoicePaymentLine[] = ad.invoice.invoices ? (ad.invoice.invoices as InvoicePaymentLine[]) : [];
    if (ad.invoice.fees && ad.invoice.fees.length > 0) {
      const [f] = ad.invoice.fees;
      this.feeContactId = f?.feePayeeId || null;
      this.feeWalletId = f?.walletId || null;
    }
    if (invoices.length > 0) {
      const invoice = invoices.find((inv) => inv.forex?.categoryId);
      if (invoice?.forex) {
        this.forexCategoryId = invoice.forex.categoryId;
      }
    }

    this.paymentLineInputs = invoices.map((invoice) => {
      return {
        invoiceId: invoice.invoiceId,
        amountPaid: invoice.amount,
        ticker: invoice.ticker,
        contactId: invoice.contactId,
        forex: invoice.forex,
        sourceTicker: invoice.coin ?? '',
        sourceAmount: invoice.coinAmount ?? '',
        walletId: invoice.walletId ?? '',
        walletDisabled: true,
      };
    });
  }

  updateInvoice(invoiceId: string, index: number) {
    this.paymentLineInputs[index].amountPaid = this.getDueAmount(invoiceId).toString();
    const invoice = this.invoices.find((i) => i.id === invoiceId);
    if (invoice && invoice.currency) {
      this.paymentLineInputs[index].ticker = invoice.currency;
    }
    this.updateTransactionData();
  }

  get totalPaymentAmount() {
    const sum = this.paymentLineInputs.reduce((prev: math.BigNumber, curr) => {
      if (!curr.amountPaid) return prev;
      return prev.plus(math.bignumber(curr.amountPaid));
    }, math.bignumber(0));
    return sum.toString();
  }

  get forexAmount() {
    const crypto = math.bignumber(this.costBasis?.cost || 0).abs();
    const totalPayment = math.bignumber(this.totalPaymentAmount);
    const totalFees =
      this.costBasis?.fees?.reduce((total, fee) => total.add(fee.costBasis.cost), math.bignumber(0)) ||
      math.bignumber(0);
    const netCrypto = crypto.sub(totalFees);
    return netCrypto.sub(totalPayment).toString();
  }

  get baseCurrency() {
    const baseCurrency = this.$store.state.currentOrg.baseCurrency;

    return getSymbolForCurrency(baseCurrency);
  }

  invoiceForexAmount(input: InvoicePaymentLineInput) {
    if (!input.sourceAmount || !input.sourceTicker || !input.amountPaid) {
      return '0';
    }
    const rate = this.exchangeRateForCurrency(input.sourceTicker);

    let historicalExchangeRate: number | undefined | null;
    if (input.invoiceId !== 'Unapplied') {
      const invoice = this.invoices.find((inv) => inv.id === input.invoiceId);

      if (!invoice) {
        return '0';
      }

      historicalExchangeRate = invoice.exchangeRate;
    }

    const forex = calculateForex(input.sourceAmount, rate, input.amountPaid, historicalExchangeRate);
    return forex.toFixed(2).toString();
  }

  updateTransactionData() {
    const transactionData: Partial<TransactionDataDTO> = { valid: true };

    //  TODO: the error array go with snack bar
    this.errors = [];

    if (!this.costBasis || !this.costBasis.valid) {
      transactionData.valid = false;
      this.errors.push('Please set a valid cost basis.');
      return this.$emit('input', transactionData);
    }
    if (!this.forexCategoryId && this.hasForexAmount) {
      transactionData.valid = false;
      this.errors.push('Please select  valid Forex category.');
      return this.$emit('input', transactionData);
    }
    if (this.paymentLineInputs.length === 0) {
      transactionData.valid = false;
      return this.$emit('input', transactionData);
    }
    let detailedFees;
    if (this.costBasis.fees && this.costBasis.fees.length > 0) {
      if (this.feeContactId === null || this.feeWalletId === null) {
        transactionData.valid = false;
        return this.$emit('input', transactionData);
      }
      const feeContactId = this.feeContactId;
      const feeWalletId = this.feeWalletId;

      detailedFees = this.costBasis.fees.map((m) => ({
        amount: m.amount,
        costBasis: m.costBasis,
        feeContactId: feeContactId,
        walletId: feeWalletId,
      }));
    }

    const coins: any = {};

    for (const input of this.paymentLineInputs) {
      if (!input.contactId) {
        transactionData.valid = false;
        this.errors.push('Please select a valid transaction contact.');
        return this.$emit('input', transactionData);
      }

      if (!input.invoiceId) {
        transactionData.valid = false;
        this.errors.push('Please select a valid invoice.');
        return this.$emit('input', transactionData);
      }

      if (!input.amountPaid) {
        transactionData.valid = false;
        this.errors.push('Please input valid payment amount.');
        return this.$emit('input', transactionData);
      }
      if (!input.sourceTicker) {
        transactionData.valid = false;
        this.errors.push('Please select a valid crypto token.');
        return this.$emit('input', transactionData);
      }
      if (!input.sourceAmount) {
        transactionData.valid = false;
        this.errors.push('Please input valid crypto amount.');
        return this.$emit('input', transactionData);
      }
      if (!coins[input.sourceTicker]) {
        coins[input.sourceTicker] = math.bignumber(0);
      }
      coins[input.sourceTicker] = coins[input.sourceTicker].plus(math.bignumber(input.sourceAmount));
    }

    const amounts = this.txn?.amounts ?? [];

    for (const crypto of amounts) {
      if (!crypto || !crypto.coin) continue;
      if (coins[crypto.coin].toString() !== math.abs(Number(crypto.value)).toString()) {
        transactionData.valid = false;
        this.errors.push(
          `Please input valid ${crypto.coin} amount, the sum should be ${math.abs(Number(crypto.value))}, now it is ${
            coins[crypto.coin]
          }`
        );
        return this.$emit('input', transactionData);
      }
    }

    const invoices: InvoicePaymentLineInput[] = this.paymentLineInputs.map((input) => {
      const invoiceId = input.invoiceId === 'Unapplied' ? undefined : input.invoiceId;

      const forexAmount = this.invoiceForexAmount(input);

      if (!Number(forexAmount)) {
        return {
          ...input,
          invoiceId,
          forex: null,
        };
      }
      const forex = {
        categoryId: this.forexCategoryId ?? '',
        fiat: this.$store.state.currentOrg.baseCurrency,
        amount: forexAmount,
      };
      return {
        ...input,
        invoiceId: invoiceId as string, //  cast here, upddate type and we can uncast
        forex,
      };
    });

    transactionData.invoice = {
      exchangeRates: this.costBasis.exchangeRates,
      invoices,
      totalAmount: math.bignumber(this.costBasis.cost).toFixed(2),
      fees: detailedFees,
      type: this.txnType === 'receive' ? InvoiceInputType.Invoice : InvoiceInputType.Bill,
    };

    this.$emit('input', transactionData);
  }

  exchangeRateForCurrency(currency: string) {
    // get selected exchange rate
    if (this.costBasis?.exchangeRates) {
      const ro = this.costBasis.exchangeRates.find((m) => m.coin === currency); // .get(currency);
      if (ro === null || ro === undefined || isNaN(Number(ro.rate))) {
        return 0;
      } else {
        return ro.rate;
      }
    } else {
      return 0;
    }
  }

  get totalFees() {
    let total = math.bignumber(0);
    if (this.txn.paidFees) {
      for (const f of this.txn.paidFees) {
        if (!f || !f.coin) continue;
        const rate = this.exchangeRateForCurrency(f.coin);
        total = total.add(math.bignumber(f.value).mul(rate)).abs();
      }
    }

    return total;
  }

  removeInvoice(index: number) {
    this.paymentLineInputs.splice(index, 1);
    this.updateTransactionData();
  }

  get hasForexAmount() {
    for (const input of this.paymentLineInputs) {
      const amount = Number(this.invoiceForexAmount(input));
      if (amount !== 0) {
        return true;
      }
    }
    return false;
  }

  get isReconciled() {
    return this.txn.reconciliationStatus === ReconciliationStatus.Reconciled;
  }

  @Watch('costBasis')
  watchCostBasis() {
    this.updateTransactionData();
  }
}
