



























































































































































































































































import * as axios from 'axios';
import gql from 'graphql-tag';
import groupBy from 'lodash/groupBy';
import * as math from 'mathjs';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type {
  Category,
  Connection,
  Contact,
  ContactAddress,
  Maybe,
  Metadata,
  Providers,
  Transaction,
  TxnLog,
  TxnTypes,
} from '@/api-svc-types';
import { TxnLineOperation, TxnType, Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import { getAccountingProviderIcon } from '@/utils/accountingProviders';
import { assertDefined } from '@/utils/guards';

import { baConfig } from '../../../../../config';
import { convertUnits, getMainUnitForCoin } from '../../../../utils/coinUtils';
import type { CostBasisDTO } from '../types';
import type { Collapse, Split, SplitLine } from './types';
import {
  calculateValueInFiat,
  looselyGetCategoryWithCode,
  multivalueTransactionDataFactory,
  prepopulateLineFromAmount,
  prepopulateLineFromTxnLine,
  splitToMultiValueTransactionItemInput,
} from './utilities';

type MetadataByType = {
  type: string;
  metadata: Metadata[];
};

@Component({})
export default class MultiValueCategorization extends BaseVue {
  @Prop({ required: true })
  readonly txn!: Transaction;

  @Prop({ required: true })
  readonly categories!: Category[];

  @Prop({ required: true })
  readonly contacts!: Contact[];

  @Prop({ required: true })
  readonly txnType!: string;

  @Prop({ required: true })
  readonly readonly!: boolean;

  @Prop({ required: true })
  readonly costBasis!: CostBasisDTO;

  @Prop({ required: true })
  public readonly accountingConnectionId!: string | null;

  @Prop({ required: true })
  public readonly connection!: Connection | null;

  @Prop({ required: true })
  public readonly getContact?: (transaction: Transaction) => string;

  @Prop({ required: true })
  public readonly metadata!: MetadataByType[];

  @Prop({ required: true })
  public readonly wallets!: Wallet[];

  notes = '';
  splits: Split[] = [];
  collapseStatus = false;
  graphAnalysisStatus = false;
  graphAnalysisFailed = false;
  loaded = 0;
  initialCollapses: Collapse[] = [];
  allowMismatch = false;

  get highPrecisionFiat() {
    return this.checkFeatureFlag('high-precision-fiat', this.features);
  }

  mounted() {
    this.populateForm();
    this.updateTransactionData();
  }

  getRemoteID(item: Contact) {
    const split = item.id.split('.');
    split.shift();
    return split.join('.');
  }

  public getProviderIcon(provider: Providers) {
    return getAccountingProviderIcon(provider);
  }

  validateForm() {
    // ensure each split has a contact, and each line has a category (if it has a value)
    let valid = true;
    this.splits.forEach((m) => {
      // for each split, contact must be defined
      if (!m.contact) {
        valid = false;
        m.valid = false;
      }

      m.lines.forEach((l) => {
        // For each line, amount and category must be defined
        if (!l.category) {
          valid = false;
          l.valid = false;
        }

        if (!l.amount) {
          valid = false;
          l.valid = false;
        }
      });
    });

    if (!this.allowMismatch && this.collapses.find((m) => m.mismatchedValues) !== undefined) {
      valid = false;
    }

    return valid;
  }

  calculateValueInFiat(coin: string | undefined, value?: math.BigNumber) {
    if (!coin || !value) {
      return 0;
    }
    return calculateValueInFiat(this.costBasis, coin, value, this.highPrecisionFiat);
  }

  addSplit() {
    this.splits.push({
      lines: [
        {
          amount: math.bignumber(0),
          metadata: new Array(this.metadata.length).fill(''),
          walletId: this.wallets?.[0]?.id || '',
        },
      ],
    });
    this.updateTransactionData();
  }

  getWalletNameById(walletId: string) {
    return this.wallets.find((wallet) => wallet.id === walletId)?.name || '';
  }

  addLine(item: Split) {
    item.lines.push({
      amount: math.bignumber(0),
      metadata: new Array(this.metadata.length).fill(''),
      walletId: this.wallets?.[0]?.id || '',
    });
    this.updateTransactionData();
  }

  deleteLine(item: Split, line: SplitLine) {
    const newLines = item.lines.filter((m) => m !== line);
    item.lines = newLines;
    this.updateTransactionData();
  }

  toggleForceZeroGainLoss(line: SplitLine) {
    const newForceZeroGainLoss = !line.forceZeroGainLoss;
    if (line.forceZeroGainLoss !== newForceZeroGainLoss) {
      line.forceZeroGainLoss = newForceZeroGainLoss;
      this.updateTransactionData();
    }
  }

  deleteSplit(split: Split) {
    const newSplits = this.splits.filter((m) => m !== split);
    this.splits = newSplits;
    this.updateTransactionData();
  }

  populateForm() {
    if (
      this.txn.accountingDetails &&
      this.txn.accountingDetails.length === 1 &&
      this.txn.accountingDetails[0]?.multivalue
    ) {
      const [ad] = this.txn.accountingDetails;
      const { multivalue } = ad;
      if (multivalue) {
        this.notes = multivalue.notes || '';

        const splits = multivalue.items.map((m) => {
          assertDefined(m);

          const lines = m.lines.map((l) => {
            assertDefined(l);

            const foundCategory = this.categories.find((cats) => cats.id === l.categoryId);
            assertDefined(foundCategory);
            const metadata: string[] = [];

            this.metadata?.forEach((m, i) => {
              // convert check to lowercase to handle netsuite metadata type being lowercase
              const type = m.type.toLowerCase();
              const found = l.metadata?.find((m) => (m?.id.toLowerCase() || '').includes(type));
              metadata.push(found?.id || '');
            });

            return {
              amount: math.bignumber(l.sourceValue.amount),
              ticker: l.sourceValue.ticker,
              description: l.description || '',
              category: foundCategory,
              metadata,
              walletId: l.walletId,
              walletDisabled: true,
              forceZeroGainLoss: l.forceZeroGainLoss,
            };
          });

          const foundContact = this.contacts.find((cats) => cats.id === m.contactId);
          assertDefined(foundContact);

          return {
            lines,
            contact: foundContact,
          };
        });
        this.$set(this, 'splits', splits);
      } else {
        // throw new Error('Bad accounting details');
      }
    } else {
      // Set the defaults based on the txn
      // First, some validation
      // Make sure that all the details we're getting are of the same coin. Not supporting multiple coins in here
      const prePopLines = [];
      const defaultWallet = this.wallets?.[0]?.id;

      if (this.txn?.txnLines?.length) {
        this.txn.txnLines.forEach((a) => {
          const l = prepopulateLineFromTxnLine(a);
          const maybeCategory = looselyGetCategoryWithCode(this.categories, this.connection?.feeAccountCode);

          const line = {
            ...l,
            metadata: new Array(this.metadata.length).fill(''),
            category: undefined as Category | undefined | null,
            walletId: a?.walletId || defaultWallet,
            walletDisabled: true,
          };
          if (a?.operation === TxnLineOperation.Fee) {
            line.category = maybeCategory;
          }
          prePopLines.push(line);

          if (a?.feeAsset || a?.feeAmount) {
            const lFee = prepopulateLineFromTxnLine({
              ...a,
              amount: a.feeAmount || '',
              asset: a.feeAsset || '',
              operation: TxnLineOperation.Fee,
            });
            const lineFee = {
              ...lFee,
              metadata: new Array(this.metadata.length).fill(''),
              description: `Fee for txn ${this.txn.id}`,
              category: maybeCategory,
              walletId: a?.walletId || defaultWallet,
              walletDisabled: true,
            };
            prePopLines.push(lineFee);
          }
        });
      } else if (this.txn && this.txn.fullAmountSetWithoutFees && this.txn.paidFees?.length) {
        //  we are going to try to pre populate the txn amounts and fees separately
        //  we will try to fill in the fee contact and category
        this.txn.fullAmountSetWithoutFees.forEach((a) => {
          const l = prepopulateLineFromAmount(a);
          const line = {
            ...l,
            metadata: new Array(this.metadata.length).fill(''),
          };

          prePopLines.push(line);
        });

        this.txn.paidFees.forEach((f) => {
          const l = prepopulateLineFromAmount(f);

          const maybeCategory = looselyGetCategoryWithCode(this.categories, this.connection?.feeAccountCode);

          const fee = {
            category: maybeCategory,
            amount: l.amount?.neg() as any,
            ticker: l.ticker,
            description: `Fee for txn ${this.txn.id}.`,
            metadata: new Array(this.metadata.length).fill(''),
          };
          prePopLines.push(fee);
        });
      } else if (this.txn && this.txn.fullAmountSet) {
        //  if we failed to do txn amount and fees separately
        //  then we are just going to do the full set
        this.txn.fullAmountSet.forEach((a) => {
          const l = prepopulateLineFromAmount(a);
          const line = {
            ...l,
            metadata: new Array(this.metadata.length).fill(''),
          };
          prePopLines.push(line);
        });
      } else if (this.txn && this.txn.details && this.txn.details.length > 0) {
        //  this is in case we do not have fullAmountSet
        this.txn.details.forEach((m) => {
          assertDefined(m);
          const { amounts } = m;
          assertDefined(amounts);
          amounts.forEach((a) => {
            const l = prepopulateLineFromAmount(a);
            const line = {
              ...l,
              metadata: new Array(this.metadata.length).fill(''),
            };
            prePopLines.push(line);
          });
        });
      }

      //  this is the final catch all
      if (prePopLines.length === 0) {
        prePopLines.push({ amount: math.bignumber(0) });
      }

      const contact = this.getTransactionContact();

      this.splits.push({
        contact,
        lines: prePopLines,
      });
    }
  }

  /**
   * get the contact object matching the value returned from the getContact function defined in the Transactions view
   */
  getTransactionContact(): Contact | undefined {
    if (!this.getContact) throw new Error('get contact function is not defined correctly');
    const contactNameOrAddress = this.getContact(this.txn);
    const contact = this.filteredContacts.find(
      (foundContact) => foundContact.name.localeCompare(contactNameOrAddress) === 0
    );
    return contact;
  }

  updateTransactionData() {
    if (!this.validateForm()) {
      this.$emit('input', { valid: false });
      return;
    }

    const baseCurrency = this.$store.state.currentOrg.baseCurrency;

    try {
      const splits = this.splits.map((split) =>
        splitToMultiValueTransactionItemInput(
          split,
          this.txnAmountTotal.toNumber(),
          baseCurrency,
          this.costBasis,
          undefined,
          this.highPrecisionFiat
        )
      );

      const exchangeRates = [];
      for (const er of this.costBasis.exchangeRates) {
        exchangeRates.push({
          coin: er.coin,
          unit: er.unit,
          fiat: er.fiat,
          rate: er.rate,
          source: er.source,
        });
      }

      const transactionData = multivalueTransactionDataFactory(splits, exchangeRates);
      this.$emit('input', transactionData);
    } catch (e) {
      this.$emit('input', { valid: false });
    }
  }

  collapseValues() {
    // When collapse value btn is clicked
    this.collapseStatus = true;
    const minimalAmount = this.bn(0.1);

    let firstMetadata: string[] | undefined;

    if (this.splits.length) {
      const splits: Split[] = [];
      this.splits.forEach((split) => {
        const collapsed: SplitLine[] = [];
        const group = groupBy(
          split.lines.filter((line) => line.ticker),
          (line) => line.ticker
        );

        Object.entries(group).forEach(([key, value]) => {
          if (value.length > 0) {
            firstMetadata = value[0].metadata;
          }

          const amount = value.reduce((total, v) => (v.amount ? total.plus(v.amount) : total), this.bn(0));
          // const amount = value.reduce((total, v) => (v.amount ? total + parseFloat(v.amount.toString()) : total), 0);
          const lowestVal = value.reduce((prev, current) => (prev.amount < current.amount ? prev : current));

          // if (key === 'ETH' && (amount > minimalAmount || amount < minimalAmount * -1)) {

          if (key === 'ETH' && (amount.gt(minimalAmount) || amount.lt(minimalAmount.mul(-1)))) {
            // < minimalAmount * -1)) {
            const typicalETH = {
              ticker: key,
              amount: lowestVal.amount,
              metadata: firstMetadata,
            };
            collapsed.push(typicalETH);

            if (!amount.sub(lowestVal.amount).eq(0)) {
              collapsed.push({
                ticker: key,
                amount: math.bignumber(amount).minus(lowestVal.amount),
                metadata: firstMetadata,
              });
            }
          } else {
            collapsed.push({ ticker: key, amount: math.bignumber(amount), metadata: firstMetadata });
          }
        });

        splits.push({ lines: collapsed });
        this.splits = splits;
      });
    }
    this.updateTransactionData();
  }

  async performGraphAnalysis() {
    this.graphAnalysisStatus = true;
    this.collapseStatus = false;
    const maybeLogs = this.txn.txnLogs;
    if (maybeLogs === undefined || maybeLogs === null) {
      throw new Error();
    }
    const logs = maybeLogs.flatMap((m) => m ?? []);
    const assets = new Array<{
      networkId: string;
      symbol: string;
      address: string;
    }>();

    logs.forEach((l) => {
      if (l.asset?.symbol && l.asset.address && l.asset.networkId) {
        assets.push({
          networkId: l.asset.networkId,
          symbol: l.asset.symbol,
          address: l.asset.address.toLowerCase(),
        });
      }
    });

    const uniqueAssets = assets.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        assets.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });

    const adArray: [string, any, TxnLog[]][] = [];
    const assetPromise = uniqueAssets.map(async (a) => {
      const ad = await this.getRemoteAddressDetails(a.networkId, a.address);
      const associatedLogs = logs.flatMap((l) =>
        l.asset?.address?.toLowerCase() === a.address.toLowerCase() ? l : []
      );
      adArray.push([a.address, ad, associatedLogs]);
    });

    await Promise.all(assetPromise);

    const lines: SplitLine[] = [];

    for (const ad of adArray) {
      const decimals = ad[1].item.decimals;
      const uniqueAddressMap = new Map<string, { out: math.BigNumber; in: math.BigNumber; asset: string }>();
      for (const log of ad[2]) {
        const from = log.from?.address?.toLowerCase();
        const to = log.to?.address?.toLowerCase();
        if (from && to) {
          const existingFrom = uniqueAddressMap.get(from);
          const existingTo = uniqueAddressMap.get(to);
          const newFrom = {
            out: (existingFrom?.out ?? this.bn(0)).add(this.bn(log.amount ?? 0).div(10 ** decimals)),
            in: existingFrom?.in ?? this.bn(0),
            asset: log.asset?.symbol ?? 'UNKNOWN',
          };
          const newTo = {
            out: existingTo?.out ?? this.bn(0),
            in: (existingTo?.in ?? this.bn(0)).add(this.bn(log.amount ?? 0).div(10 ** decimals)),
            asset: log.asset?.symbol ?? 'UNKNOWN',
          };
          uniqueAddressMap.set(from.toLowerCase(), newFrom);
          uniqueAddressMap.set(to.toLowerCase(), newTo);
        }
      }
      const uniqueAddressArray = Array.from(uniqueAddressMap);
      const netChangeArray = uniqueAddressArray.flatMap((m) => {
        if (m[1].in.eq(0) && m[1].out.eq(0)) {
          return [];
        }
        return {
          address: m[0],
          netChange: m[1].in.sub(m[1].out),
          symbol: m[1].asset,
        };
      });

      // First, let's try to see if any single address is a 'passthrough with no change.'
      // If so, we do another check for an origin addy.
      const netZeroAddresses = netChangeArray.flatMap((m) => {
        if (m.netChange.eq(this.bn(0))) {
          return m.address;
        } else {
          return [];
        }
      });

      if (netZeroAddresses.length === 1) {
        const passthroughAddress = netZeroAddresses[0];

        const netOutgoingAddresses = netChangeArray.flatMap((m) => {
          if (m.netChange.lt(this.bn(0))) {
            return m.address;
          } else {
            return [];
          }
        });

        if (netOutgoingAddresses.length === 1) {
          const originAddress = netOutgoingAddresses[0];
          const newLines = netChangeArray.flatMap((m) => {
            if (
              m.address !== (originAddress || passthroughAddress) &&
              m.netChange.toString() !== this.bn(0).toString()
            ) {
              const line: SplitLine = {
                amount: this.bn(0).sub(m.netChange),
                ticker: m.symbol,
                description: `Recipient: ${m.address}`,
              };
              return line;
            } else {
              return [];
            }
          });
          lines.push(...newLines);
        }
      } else {
        console.log('No passthrough addresses found for split by ultimate destination.');
      }
    }

    if (this.txn.paidFees) {
      this.txn.paidFees.forEach((f) => {
        const l = prepopulateLineFromAmount(f);

        const maybeCategory = looselyGetCategoryWithCode(this.categories, this.connection?.feeAccountCode);

        const fee = {
          category: maybeCategory ?? undefined,
          amount: l.amount?.neg() as any,
          ticker: l.ticker,
          description: `Fee for txn ${this.txn.id}.`,
        };
        lines.push(fee);
      });
    }
    this.splits[0].lines = lines;
    this.graphAnalysisStatus = false;

    // validation stuff
    const splits: SplitLine[] = [];
    this.splits.forEach((split) => {
      const line = split.lines.filter((line) => line.ticker);
      splits.push(...line);
    });

    const group = groupBy(splits, (line) => {
      return line.ticker;
    });

    Object.entries(group).forEach(([key, value], index) => {
      const amount = value.reduce((total, v) => (v.amount ? total.plus(v.amount) : total), this.bn(0));
      const mismatchedValues: boolean =
        !!this.loaded && this.initialCollapses[index] && !amount.eq(this.initialCollapses[index].amount);
      if (mismatchedValues) {
        this.graphAnalysisFailed = true;
      }
    });

    if (this.graphAnalysisFailed) {
      this.splits = [];
      this.populateForm();
    }
    this.updateTransactionData();
  }

  async getRemoteAddressDetails(networkId: string, address: string) {
    const addressDetailsUrl = `${baConfig.addressSvcUrl}/networks/${networkId}/addresses/${address}`;
    const useAxios = axios as any;
    const resp = await useAxios.get(addressDetailsUrl);
    if (resp && resp.status === 200) {
      return resp.data;
    } else {
      throw new Error('Problem getting address details');
    }
  }

  getCollapses() {
    if (this.checkFeatureFlag('categorization-validation-hotfix', this.features)) {
      if (this.splits.length) {
        const splits: SplitLine[] = [];
        this.splits.forEach((split) => {
          const line = split.lines.filter((line) => line.ticker);
          splits.push(...line);
        });

        const grouped: Collapse[] = [];
        const group = groupBy(splits, (line) => {
          return (line.ticker ?? '') + ' ' + (line.walletId ?? '');
        });
        const initialCollapseGroup = groupBy(this.initialCollapses, (line) => {
          return (line.ticker ?? '') + ' ' + (line.walletId ?? '');
        });

        Object.entries(group).forEach(([key, value], index) => {
          const amount = value.reduce((total, v) => (v.amount ? total.plus(v.amount) : total), this.bn(0));

          const mismatchedValues: boolean =
            !!this.loaded && (!initialCollapseGroup[key]?.[0] || !amount.eq(initialCollapseGroup[key]?.[0]?.amount)); // !== this.initialCollapses[index].amount.toNumber();

          grouped.push({
            ticker: value[0].ticker ?? '',
            amount: math.bignumber(amount),
            color: this.loaded && Boolean(mismatchedValues) ? 'error--text' : 'success--text',
            mismatchedValues,
            walletId: value[0].walletId,
          });
        });

        if (!this.loaded) {
          this.initialCollapses = grouped;
        }
        this.loaded++;
        return grouped;
      }
      return [];
    } else {
      if (this.splits.length) {
        const splits: SplitLine[] = [];
        this.splits.forEach((split) => {
          const line = split.lines.filter((line) => line.ticker);
          splits.push(...line);
        });

        const grouped: Collapse[] = [];
        const group = groupBy(splits, (line) => {
          return line.ticker;
        });

        Object.entries(group).forEach(([key, value], index) => {
          const amount = value.reduce((total, v) => (v.amount ? total.plus(v.amount) : total), this.bn(0));

          const mismatchedValues: boolean =
            !!this.loaded && this.initialCollapses[index] && !amount.eq(this.initialCollapses[index].amount); // !== this.initialCollapses[index].amount.toNumber();

          grouped.push({
            ticker: key,
            amount: math.bignumber(amount),
            color: this.loaded && Boolean(mismatchedValues) ? 'error--text' : 'success--text',
            mismatchedValues,
          });
        });

        if (!this.loaded) {
          this.initialCollapses = grouped;
        }
        this.loaded++;
        return grouped;
      }
      return [];
    }
  }

  get coin() {
    const { details } = this.txn;

    assertDefined(details);
    const [d] = details;
    assertDefined(d);
    const { amounts } = d;
    assertDefined(amounts);
    const [a] = amounts;
    assertDefined(a);
    const { coin } = a;
    assertDefined(coin);
    return coin;
  }

  get coinUnit() {
    return getMainUnitForCoin(this.coin);
    // return this.txn.details[0].amounts[0].unit;
  }

  get sumValid() {
    return this.amountTotal.eq(this.txnAmountTotal);
  }

  get amountTotal() {
    let total = math.bignumber(0);
    this.splits.forEach((m) => {
      m.lines.forEach((l) => {
        if (l.amount) {
          total = total.plus(l.amount);
        }
      });
    });

    return total;
  }

  get txnAmountTotal() {
    let amountTotal = math.bignumber(0);

    assertDefined(this.txn.details);

    for (const d of this.txn.details) {
      assertDefined(d);
      const { amounts } = d;

      assertDefined(amounts);
      for (const m of amounts) {
        assertDefined(m);
        const { value, coin, unit } = m;
        assertDefined(coin);
        assertDefined(unit);
        const val = math.bignumber(value);
        const targetUnit = getMainUnitForCoin(coin);
        const converted = convertUnits(coin, unit, targetUnit, val);
        assertDefined(converted);
        const exRate = this.txn?.exchangeRates?.find(
          (er) => er?.coin === coin && er?.coinUnit === unit && er?.fiat === this.$store.state.currentOrg.baseCurrency
        );

        if (exRate) {
          const rate = this.convertBigNumberScalar(exRate?.rate);
          const converted2 = converted.times(rate);
          assertDefined(converted2);
          amountTotal = amountTotal.plus(converted2);
        } else {
          amountTotal = amountTotal.plus(converted);
        }
      }
    }
    return amountTotal;
  }

  get filteredCategories() {
    return this.categories;
  }

  categoryFilter(item: any, queryText: string, itemText: string) {
    const text = item.code + ' ' + itemText;
    return text.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
  }

  get filteredContacts() {
    return this.contacts;
  }

  get collapses() {
    return this.getCollapses();
  }

  @Watch('splits', { deep: true })
  watchSplits(splits: Split[]) {
    let changedCategory = false; // flag to check if category was changed to prevent unnecessary updates
    // If a split has a contact, but no category, set the category to the default category for the contact
    splits.map((split) => {
      if (split.contact && !split.lines[0].category) {
        let categoryId: Maybe<string> | undefined;
        let address: ContactAddress | undefined;
        const to = this.txn.to;
        const from = this.txn.from;
        switch (this.txnType) {
          case 'receive':
            address = split.contact.addresses.find((address) => {
              if (from) return address.address === from[0]?.address;
            });
            if (address?.defaultRevenueCategoryId) categoryId = address.defaultRevenueCategoryId;
            else categoryId = split.contact.defaultRevenueCategoryId;
            break;
          // TODO: this case does not work for category overrides due to missing data from txn.to (ask robinson for details)
          case 'spend':
            address = split.contact.addresses.find((address) => {
              if (to) return address.address.toLowerCase() === to[0]?.address.toLowerCase();
            });
            if (address?.defaultExpenseCategoryId) categoryId = address.defaultExpenseCategoryId;
            else categoryId = split.contact.defaultExpenseCategoryId;
            break;

          default:
            break;
        }
        // const revenueCategoryId = split.contact.defaultRevenueCategoryId;
        const category = this.categories.find((category) => category.id === categoryId);
        split.lines[0].category = category;
        changedCategory = true;
      }
    });
    if (changedCategory) {
      this.updateTransactionData();
    }
  }

  @Watch('costBasis')
  watchCostBasis() {
    this.updateTransactionData();
  }

  @Watch('allowMismatch')
  watchAllowMismatch() {
    this.updateTransactionData();
  }
}
